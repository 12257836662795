<template>
  <div>
    <div :class="hasBanner ? 'generic-form-body' : ''">
      <template-base>
        <div class="title-header-content">
          <PageTitle
            :title="`${editing ? 'Edição' : 'Cadastro'} de ${title}`"
          />
          <Button
            @click="goToRastreabilidade"
            v-if="editing && isMaterial"
            class="rastrear-button"
            variant="grayscale"
            type="text"
            size="only-icon"
            icon="truck"
            v-b-tooltip.hover.bottom="'Rastrear'"
          />
        </div>
        <form @submit.stop.prevent="onSubmit">
          <ul
            v-if="$validator.errors.all().length > 0"
            class="alert alert-danger"
          >
            <li
              class="mb-2"
              v-for="error in $validator.errors.all()"
              :key="error.id"
            >
              {{ error }}
            </li>
          </ul>

          <slot :validateState="validateState" :isBusy="isBusy" />

          <slot
            name="buttons"
            :isBusy="isBusy"
            :cancel="cancel"
            :exclude="exclude"
            :onSubmit="onSubmit"
            :exclusionEnabled="exclusionEnabled"
          >
            <b-row align-h="between" :class="hasBanner ? 'button-with-banner' : 'pt-4'">
              <b-col>
                <Button
                  variant="grayscale"
                  @click="cancel"
                  :class="editing && exclusionEnabled ? 'rounded left' : ''"
                  :text="onlyVisualize ? 'Fechar' : 'Cancelar'"
                  :disabled="isBusy"
                />
                <Button
                  v-if="editing && exclusionEnabled"
                  variant="grayscale"
                  type="text"
                  class="rounded right"
                  @click="exclude"
                  :disabled="isBusy"
                  text="Excluir"
                />
              </b-col>
              <b-col class="text-right">
                <Button
                  v-if="!onlyVisualize"
                  :disabled="isBusy"
                  text="Salvar"
                  @click="onSubmit"
                />
              </b-col>
            </b-row>
          </slot>
        </form>
        <b-overlay
          no-wrap
          :show="loading"
          variant="light"
          opacity="0.42"
          rounded="lg"
          spinner-small
        >
          <template #overlay>
            <b-spinner
              class="spinner-save"
            />
          </template>
        </b-overlay>
      </template-base>
      <slot v-if="hasBanner" name="banner">
        <div class="rigth-banner">
          <h3>Material</h3>
          <label>Tipo de material:</label>
          <div class="info">{{ model.material.tipoMaterial ? model.material.tipoMaterial.nome : '-' }}</div>
          <br />

          <label>Descrição do material/ID:</label>
          <div class="info">
            <span>{{ model.material.descricao || '-' }}/{{ model.material.id_material }}</span>
          </div>
          <br />

          <label>Quantidade de itens cadastrados:</label>
          <div class="info">
            <span>{{ model.material.quantidade_itens }} itens</span>
          </div>
          <br />

          <label>Quantidade de itens faltantes:</label>
          <div class="info">
            <span>{{ model.material.quantidade_itens - model.material.quantidade_atual_itens }} itens</span>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<style scoped>
.feather {
  margin-bottom: 4px;
}
.spacer {
  margin-left: auto;
}
</style>

<script>
import locale from '@/locale';
import loginService from '@/services/login';
import { show403 } from '@/helpers/auth';
import GenericApi from '@/services/genericRequest';
import TemplateBase from '@/templates/Base';
import PageTitle from '@/templates/PageTitle';
import Button from '@/components/Utils/Button';

export default {
  name: 'GenericForm',
  components: {
    TemplateBase,
    PageTitle,
    Button,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    permissionsToWrite: {
      type: Array,
      default() {
        return [];
      },
    },
    permissionsToEdit: {
      type: Array,
      default() {
        return [];
      },
    },
    permissionsToDelete: {
      type: Array,
      default() {
        return [];
      },
    },
    onlyVisualize: {
      type: Boolean,
      default: false,
    },
    model: {
      type: Object,
      required: true,
    },
    idModel: {
      type: String,
      required: true,
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    route: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    exclusionEnabled: {
      type: Boolean,
      default: true,
    },
    previousRoute: {
      type: String,
      required: false,
    },
    editRoute: {
      type: String,
      required: false,
    },
    etiquetaRoute: {
      type: String,
      required: false,
    },
    loadOnSave: {
      type: Boolean,
      default: false,
    },
    hasBanner: {
      type: Boolean,
      default: false,
    },
    isMaterial: {
      type: Boolean,
      default: false,
    },
    refreshPageAfterSave: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CAN_WRITE: loginService.verifyPermissions(this.permissionsToWrite),
      CAN_DELETE: loginService.verifyPermissions(this.permissionsToDelete),
      CAN_EDIT: loginService.verifyPermissions(this.permissionsToEdit),
      isBusy: false,
      loading: false,
    };
  },

  async created() {
    if (this.editing && !this.CAN_EDIT) {
      show403();
      return;
    }
    this.$validator.localize('pt_BR', locale);
    if (this.editing) {
      this.isBusy = true;
      try {
        const id = this.getIdFromObject(this.model);
        const result = await this.api.getOne(id, this.route);

        if (result.deletedAt) {
          if (this.previousRoute) this.$router.push({ name: this.previousRoute });
          return;
        }

        this.$emit('updateModel', result);
        this.isBusy = false;
      } catch (error) {
        let errorMessage = 'Ocorreu um problema ao recuperar o objeto';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Erro',
          text: errorMessage,
          icon: 'error',
          button: 'Continuar...',
        });

        if (this.previousRoute) this.$router.push({ name: this.previousRoute });
      }
    }
  },

  methods: {
    cancel() {
      if (this.onlyVisualize) {
        this.$emit('canceled');
        if (this.previousRoute) this.$router.push({ name: this.previousRoute });
        return;
      }

      swal({
        title: 'Cancelamento de formulário',
        text: 'Deseja cancelar a edição do formulário?',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      }).then((value) => {
        if (value) {
          this.$emit('canceled');
          if (this.previousRoute) this.$router.push({ name: this.previousRoute });
        }
      });
    },
    goToRastreabilidade() {
      this.$router.push(`/material/rastrear/${this.$router.history.current.params.id}`);
    },
    onSubmit() {
      if ((!this.CAN_WRITE && !this.CAN_EDIT) || this.onlyVisualize) {
        show403();
        return;
      }
      if (this.isBusy) return;

      this.$parent.$validator.resume();

      this.$parent.$validator.validateAll().then(async (result) => {
        if (!result || this.isBusy) {
          return;
        }

        this.isBusy = true;
        if (this.editing) {
          this.update();
        } else {
          this.save();
        }
      });
    },

    async save() {
      try {
        if (this.loadOnSave) this.loading = true;
        const result = await this.api.create(this.model, this.route);
        this.loading = false;

        await swal({
          title: 'Bom trabalho!',
          text: 'O registro foi criado na base de dados com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });

        this.$emit('created');

        if (this.etiquetaRoute && (!result.material || !result.material.length)) {
          const id = this.getIdFromObject(result);
          if (!id) {
            this.$router.replace({
              name: this.previousRoute,
            });
          } else {
            this.$router.replace({
              name: this.etiquetaRoute,
              params: {
                id,
              },
              query: {
                created: true,
              },
            });
          }
        } else if (result.material && result.material.length) {
          this.$router.replace({
            name: this.previousRoute,
            query: {
              cadastroEmLote: true,
              id_lote_material: result.material[0][0].id_lote_material,
            },
          });
        } else if (this.previousRoute) {
          if (this.refreshPageAfterSave) {
            window.location.href = `/${this.previousRoute}`;
          } else {
            const id = this.getIdFromObject(result);
            this.$router.replace({
              name: this.previousRoute,
              params: {
                id,
              },
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            errorMessage || 'Ocorreu um problema ao tentar criar o registro. Por favor entre em contato com suporte',
          icon: 'error',
          button: 'Continuar',
        });
      } finally {
        this.isBusy = false;
      }
    },

    async update() {
      try {
        if (this.loadOnSave) this.loading = true;
        const id = this.getIdFromObject(this.model);
        await this.api.update(id, this.model, this.route);
        this.loading = false;

        await swal({
          title: 'Bom trabalho!',
          text: 'O registro foi atualizado com sucesso!',
          icon: 'success',
          button: 'Continuar',
        });
        if (this.refreshPageAfterSave) {
          this.$router.go();
        }
      } catch (error) {
        this.loading = false;
        let errorMessage;
        if (
          error.response
          && error.response.data
          && error.response.data.error
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        await swal({
          title: 'Oops!',
          text:
            errorMessage || 'Ocorreu um problema ao tentar atualizar o registro.',
          icon: 'error',
          button: 'Continuar',
        });
      } finally {
        this.isBusy = false;
      }
    },

    async exclude() {
      if (!this.CAN_DELETE) {
        show403();
        return;
      }
      if (!this.editing || !this.exclusionEnabled || this.isBusy) return;

      const deleteConfirmation = await swal({
        title: 'Excluir',
        text:
          'Tem certeza que deseja excluir o registro?\nEsta ação é definitiva e sem retorno.',
        icon: 'warning',
        buttons: { cancel: 'Não', confirm: 'Sim' },
      });

      if (deleteConfirmation) {
        this.isBusy = true;
        try {
          const id = this.getIdFromObject(this.model);
          let filters = {};

          if (this.model.id_item_avulso) {
            filters = {
              id_item_avulso: this.model.id_item_avulso,
            };
          }

          await this.api.delete(id, this.route, filters);

          await swal({
            title: 'Bom trabalho!',
            text: 'O registro foi removido com sucesso!',
            icon: 'success',
            button: 'Continuar',
          });

          this.$emit('deleted');

          if (this.previousRoute) {
            if (this.refreshPageAfterSave) {
              window.location.href = `/${this.previousRoute}`;
            } else {
              this.$router.push({ name: this.previousRoute });
            }
          }
        } catch (error) {
          let errorMessage;
          if (
            error.response
            && error.response.data
            && error.response.data.error
          ) {
            errorMessage = error.response.data.error.errorMessage;
          }

          await swal({
            title: 'Oops!',
            text:
              errorMessage || 'Ocorreu um problema ao tentar excluir o registro.',
            icon: 'error',
            button: 'Continuar',
          });
        } finally {
          this.isBusy = false;
        }
      }
    },

    validateState(ref) {
      if (
        this.$parent.veeFields[ref]
        && document.getElementsByName(ref)[0]
        && !document.getElementsByName(ref)[0].disabled
        && (this.$parent.veeFields[ref].dirty || this.$parent.veeFields[ref].validated)
      ) {
        return !this.$parent.veeErrors.has(ref);
      }

      return null;
    },

    getIdFromObject(object) {
      return object[this.idModel];
    },
  },
};
</script>

<style>
.title-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.spinner-save {
  position: relative;
  color: #209f85;
  width: 5rem;
  height: 5rem;
}
.generic-form-body {
  display: flex;
  padding: 0 2.5rem;
}
.rigth-banner {
  background-color: #21CCA9;
  border-radius: 0px 5px 5px 0px;
  padding: 2rem;
  max-width: 232px;
  color: #fff;
}
label {
  font-weight: 500;
}
h3 {
  margin-bottom: 2rem;
}
.info {
  font-weight: 400;
}
.button-with-banner {
  margin-top: 13rem;
}
.rastrear-button {
  margin-bottom: 4.2rem;
}
</style>
